<template>
  <div class='common_box parameter'>
    <div class="common_title">
      <h3>摄像头参数</h3>
    </div>
    <div class="parimage">
      <div>
        <par-image ref="parimage" v-if="isShow" @imgBox="imgBox" />
        <div v-else class="empty">
          <el-empty description="暂无封面展示"></el-empty>
        </div>
      </div>
      <div>
        <div class="info">
          <el-form :model="info" :rules="rules" label-width="120px" ref="info" :hide-required-asterisk="true">
            <el-form-item label="设备ID：">
              <el-input v-model="info.id" :disabled="true">
              </el-input>
            </el-form-item>
            <el-form-item prop="grabMode" label="抓图方式：">
              <el-select v-model="info.grabMode" placeholder="请选择抓图方式" @change="changeGrab">
                <el-option label="实时" :value="1"></el-option>
                <el-option label="动检" :value="2"></el-option>
                <el-option label="轮询" :value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="抓图开始时间：">
              <el-select v-model="info.start" placeholder="请选择抓图开始时间" :disabled="disabled">
                <el-option v-for="(item,index) in timeList" :key="index" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="抓图结束时间：">
              <el-select v-model="info.stop" placeholder="请选择抓图结束时间" :disabled="disabled">
                <el-option v-for="(item,index) in timeList" :key="index" :label="item" :value="item"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="algorithm" label="场景算法：">
              <el-select v-model="info.algorithm" multiple placeholder="请选择场景算法">
                <el-option v-for="item in algorithmList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-button @click="$router.go(-1)">返回</el-button>
              <el-button type="primary" :loading="loginLoading" @click="submitForm('info')">修改</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ParImage from './components/image.vue'
import { getArguments, getArgumentsImage, editArguments } from '@/api'
import { mapGetters } from 'vuex'
export default {
  components: { ParImage },
  data () {
    return {
      loginLoading: false,
      disabled: false,
      rules: {
        // start: [
        //   { required: true, message: '请选择抓图开始时间', trigger: 'change' }
        // ],
        algorithm: [
          { required: true, message: '请选择场景算法', trigger: 'change' }
        ],
        grabMode: [
          { required: true, message: '请选择抓图方式', trigger: 'change' }
        ],
        // stop: [
        //   { required: true, message: '请选择抓图结束时间', trigger: 'change' }
        // ]
      },
      id: this.$route.query.id,
      info: {
        algorithm: []
      },
      image: '',
      timeList: [],
      isShow: true,
      // 画框数据
      imgBoxNumber: {}
    };
  },
  created () {
    this.init()
  },
  mounted () {

  },
  computed: {
    ...mapGetters(['algorithmList'])
  },
  methods: {
    init () {
      getArguments(this.id).then(res => {
        this.info = { ...this.info, ...res.data.data }
        if (this.info.algorithms.length > 0) {
          this.info.algorithms.map(el => {
            this.info.algorithm.push(el.id)
          })
        }
        if (this.info.grabMode === 3) {
          this.disabled = true
        }
      })
      getArgumentsImage(this.id).then(res => {
        this.image = res.data.data
        // this.$refs.parimage.init(this.info)
        this.isShow = true
      }).catch(() => {
        this.isShow = false
        // console.log(Boolean(this.image))
      })
      for (let i = 1; i <= 24; i++) {
        this.timeList.push(i)
      }
    },
    changeGrab (val) {
      console.log('val :>> ', val);
      if (val === 3) {
        this.disabled = true
      } else {
        this.disabled = false
      }
    },
    // change () {
    //   // console.log(typeof this.info.algorithm)
    //   // this.info.algorithm = this.algorithm.toString()
    //   // console.log(this.info)
    // },
    // 图片画框数据
    imgBox (val) {
      this.imgBoxNumber = val
      // console.log('val :>> ', val);
    },
    // 修改
    submitForm () {
      this.$refs.info.validate((valid) => {
        if (valid) {
          // console.log(this.info)
          // const algorithmId = []
          // this.info.algorithms.map(el => {
          //   algorithmId.push(el.id)
          // })
          const { xmax,
            xmin,
            ymax,
            ymin } = this.imgBoxNumber
          const params = {
            id: this.info.id,
            grabMode: this.info.grabMode,
            start: this.info.start,
            stop: this.info.stop,
            algorithmIds: this.info.algorithm.toString(),
            siteId: this.info.siteId,
            roomId: this.info.roomId,
            xmax,
            xmin,
            ymax,
            ymin
          }
          if (!params.start) {
            delete params.start
            delete params.stop
          }
          // console.log(params)
          editArguments(this.$tools.concatParams(params)).then(() => {
            this.$message.success('参数修改成功')
            this.$router.go(-1)
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  }
};
</script>

<style scoped lang="scss">
.parameter {
  .parimage {
    width: 100%;
    padding: 0 20px;
    // margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .info {
    ::v-deep .el-form {
      // width: 60%;
      // margin: 0 auto;
      .el-form-item {
        margin-bottom: 40px;
      }
      .el-form-item__label {
        color: #30c6fe;
      }
      .el-form-item__content {
        text-align: right;
        .el-input {
          .el-input__inner {
            background: none;
            border: 1px solid #30c6fe;
            color: #fff;
          }
        }
        // .is-disabled {
        //   .el-input__inner {
        //     border: none;
        //   }
        // }
        .el-select {
          width: 100%;
        }
        .el-textarea {
          .el-textarea__inner {
            background: none;
            border: 1px solid #30c6fe;
            color: #fff;
          }
        }
      }
    }
  }
}
</style>
